namespace app.pages {
    "use strict";

    export interface IPageConstants {
        LegalTermsConditions: number;
		Homepage: number;
		Teleconferences: number;
		Legal: number;
		Glossary: number;
		Links: number;
		Finance: number;
		Documents: number;
		Media: number;
		SIEFManagement: number;
		Positions: number;
    }

    angular
        .module("app.pages")
        .constant("pageConstants", <IPageConstants>{
            LegalTermsConditions: 2,
			Homepage: 3,
			Teleconferences: 4,            
            Legal: 5,
            Glossary: 6,
			Links: 8,
            Finance: 10,
            Documents: 12,
            Media: 13,
			SIEFManagement: 15,
			Positions: 16,
        });
}

namespace app.documents {
    "use strict";

    export interface IDocumentCategoryConstants {
        issueDocuments: number;
        mediaDocuments: number;
		financeDocuments: number;
		homepageDocuments: number;
		siefDocuments: number;
		positionsDocuments: number;
        legalDocuments: number;
    }

    angular
        .module("app.documents")
        .constant("documentCategoryConstants", <IDocumentCategoryConstants>{
			issueDocuments: 4,
			homepageDocuments: 6,
			mediaDocuments: 7,
            financeDocuments: 61,
            siefDocuments: 1864,
            positionsDocuments: 2192,
            legalDocuments: 4526,
        });
}
